.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
  border: 1px solid var(--beautiful-red);
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 20px;
}

.warning {
  color: var(--beautiful-red);
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 5px;
}

.close_button {
  height: 40px;
  background-color: #212529;
  border: 1px solid #9c9c9c;
  &:hover {
    background-color: #0e11174c;
  }
}

.submit_button {
  height: 40px;
  background-color: var(--beautiful-red);
  border: 1px solid #777;
}

.deletion_list_title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.deletion_list {
  max-height: 260px;
  overflow-y: auto;
}
