.table {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
  overflow: hidden auto;
}

.error_result {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user {
  width: 120px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
