.batch_actions_menu {
  display: flex;
  justify-content: start;
  align-items: center;
}

.batch_button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.batch_button_enabled {
  cursor: pointer;
  color: var(--deloitte-green);
}

.batch_button_disabled {
  cursor: default;
  color: #ffffff40;
}

.vertical_divider {
  width: 1px;
  height: 100%;
  background-color: #303030;
  margin-left: 14px;
  margin-right: 18px;
}
