:root {
  --deloitte-green: #26890d;
  --deloitte-green-pressed: #87bc25ba;
  --beautiful-red: #da291c;
}

/* Add these styles to override Ant Design primary button colors */
.ant-btn-primary {
  background-color: var(--deloitte-green) !important;
  border-color: var(--deloitte-green) !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--deloitte-green-pressed) !important;
  border-color: var(--deloitte-green-pressed) !important;
}

/* For disabled state */
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus {
  background-color: rgba(38, 137, 13, 0.5) !important;
  border-color: rgba(38, 137, 13, 0.5) !important;
}

.highlight-text {
  background-color: rgb(117, 163, 33, 0.5);
  font-weight: 600;
  padding-right: 4px;
}

.ant-tabs-tabpane-active table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  /* font-size: 16px; */
  text-align: left;
}

/* Table header styles */
.ant-tabs-tabpane-active table thead th {
  background-color: #303030;
  /* color: #333; */
  padding: 12px 15px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
}

/* Table body styles */
.ant-tabs-tabpane-active table tbody td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

/* Adding some margin to the table */
.ant-tabs-tabpane-active {
  margin: 20px;
}

/* Adding padding to the table cells */
.ant-tabs-tabpane-active table td {
  padding: 12px 15px;
}

/* Adding padding to the table headers */
.ant-tabs-tabpane-active table th {
  padding: 12px 15px;
}

/* Adding margin to the table */
.ant-tabs-tabpane-active table {
  margin: 20px 0;
}

.excel-button {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  font-size: 10px;
  line-height: 1.5714285714285714;
  height: 32px;
  padding: 2px 7px;
  border-radius: 6px;
  -webkit-appearance: button;
  color: #26890d;
  background: transparent;
  border-color: #26890d;
  box-shadow: none;
  cursor: pointer;
}

.excel-button:hover {
  background: transparent;
  color: #93b744;
  border-color: #93b744;
}

#listboxContainer > div > div:nth-child(2) {
  z-index: 1000 !important;
}
.ant-btn-background-ghost:not(:disabled) {
  color: #fff !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  margin-top: 0;
}

.ant-collapse-header-text {
  width: 90%;
}
