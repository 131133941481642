.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.close_button {
  height: 40px;
  background-color: #212529;
  border: 1px solid #9c9c9c;
  &:hover {
    background-color: #0e11174c;
  }
}

.submit_button {
  height: 40px;
  background-color: var(--deloitte-green);
  border: 1px solid #777;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;
}

.content_part {
  width: 50%;
  border: 1px solid #424242;
  border-radius: 8px;
}

.content_header {
  box-sizing: border-box;
  padding: 15px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #424242;
}

.files_container {
  max-height: 260px;
  overflow-y: auto;
}

.content_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0;
  box-sizing: border-box;
  padding: 15px;
}

.content_item_data {
  width: 90%;
}

.content_item:not(:last-child) {
  border-bottom: 1px solid #424242;
}

.middle_icon {
  align-self: center;
}
