.form {
  padding: 10px 2px;
  display: flex;
  flex-direction: column;
}

.language_input {
  margin-bottom: 40px;
}

.button_icon {
  width: 20px;
  height: 20px;
}

.modal_icon {
  width: 30px;
  height: 30px;
}

.description_tooltip {
  max-width: 600px;
  white-space: normal;
  word-wrap: break-word;
}

.tool_content {
  padding: 10px;
}

.tool_notice {
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #87ceeb;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 10px;
}

.notice_header {
  color: #87ceeb;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 5px;
}

.notice_content {
  padding: 5px 10px;
}

.file_extensions {
  font-weight: 700;
  font-style: italic;
}

.translation_button {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}
