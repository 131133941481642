.files_bar {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  background-color: #23232a;
  transition: 0.3s;
}

.files_bar_header {
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.files_bar.closed {
  min-width: 60px;
  max-width: 60px;
}

.toggle_button {
  display: flex;
  margin-right: 10px;
  padding: 4px 6px 6px 4px;
  color: #9ea6a2;
  font-size: 20px;
  cursor: pointer;
  border-radius: 15%;
  transition: 0.3s;
  z-index: 100;
  &:hover {
    background-color: #525860;
  }
}

.toggle_button.closed {
  transform: scaleX(-1);
}

@media (max-width: 1150px) {
  .files_bar {
    display: none;
  }
}

.search_input {
  width: calc(100% - 20px);
  margin: 0 10px 10px;
}

.files_list_title {
  padding: 6px 6px 6px 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.files_list_title_text {
  margin: 0 10px;
  font-size: 16px;
  font-weight: 700;
}

.text_n_sort {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.files_sort_button {
  color: #fff;
  line-height: 1;
}

.sort_icon {
  width: 20px;
  height: 20px;
}

.files_delete_button {
  color: var(--beautiful-red);
  line-height: 1;
  padding: 2px 5px !important;
  &:hover {
    background-color: var(--beautiful-red) !important;
  }
  &:disabled {
    background: none !important;
  }
}

.files_delete_icon {
  width: 20px;
  height: 20px;
}

.items_container {
  flex-grow: 1;
  overflow-y: auto;
}

.files_list_item {
  
  padding: 0px 5px!important;
  min-height: 40px;
  height: auto;
  align-items: flex-start;
  &:hover {
    background-color: #434348;
  }
}

.files_list_item_selected {
  background-color: #525860;
}

.processing_file {
  opacity: 0.5;
}

.file_checkbox {
  opacity: 0;
  flex-shrink: 0;
  margin-top: 2px;
}

.file_checkbox_checked {
  opacity: 1;
}

.files_list_item:hover .file_checkbox {
  opacity: 1;
}

.files_list_item_name {
  width: 100%;
  margin: 0 10px;
  font-size: 10px;
  overflow-x: hidden;
  white-space: normal;
  word-break: break-word;
  line-height: 1.4;
  padding: 2px 0;
}

.preview_button {
  cursor: pointer;
}

.file_delete_wrapper {
  opacity: 0;
  line-height: 1;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 8px;
  margin-top: 2px;
}

.files_list_item:hover .file_delete_wrapper {
  opacity: 1;
}

.file_delete_icon {
  width: 20px;
  height: 20px;
  color: #fff;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #ffffff98;
  }
}

.empty_files_state {
  font-size: 12px;
  text-align: center;
  margin-top: 40px;
}

.file_process_spinner {
  display: block;
}

.clear_file_process {
  display: none;
}

.file_process:hover .file_process_spinner {
  display: none;
}

.file_process:hover .clear_file_process {
  display: block;
}

.files_list_item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.02);
}

.folder_path {
  font-size: 0.9em;
  color: #8B8B8B; /* Muted gray for folder path */
}

.file_extension {
  color: #6B8EAE; /* Soft blue for file extensions */
}

.folder_icon, .file_icon {
  opacity: 0.7;
  margin-right: 4px;
}

.file_name {
  color: #E1E1E1; /* Brighter color for the main filename */
}

/* Optional: Add hover state for better interactivity */
.files_list_item:hover .file_name {
  color: #FFFFFF;
}

.files_list_item:hover .folder_path {
  color: #A1A1A1;
}
