.all_tab {
  height: calc(100vh - 309px);
  overflow: auto;
}

.all_tab_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
