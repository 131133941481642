.files_bar {
  min-width: 400px;
  max-width: 400px;
}

.files_list_title_label {
  margin-left: 20px;
  font-size: 18px;
}

.items_container {
  height: calc(100vh - 288px - 85px - 21px);
  overflow-y: auto;
}

.files_list_item {
  padding: 0px !important;
  min-height: 40px;
  height: auto;
  justify-content: flex-start !important;
  gap: 10px;
  border-radius: 3px;
  &:hover {
    background-color: #434348;
  }
}

.processing_file {
  opacity: 0.5;
}

.files_list_item_name {
  margin: 0 10px;
  font-size: 14px;
  overflow-x: hidden;
  white-space: normal;
  word-break: break-word;
  line-height: 1.4;
}

.preview_button {
  cursor: pointer;
}

.folder_path {
  font-size: 0.9em;
  color: #8b8b8b;
}

.files_list_item:hover .folder_path {
  color: #a1a1a1;
}

.file_name {
  color: #e1e1e1;
}

.files_list_item:hover .file_name {
  color: #ffffff;
}

.file_extension {
  color: #6b8eae;
}
