.approval_container {
  height: calc(100vh - 309px);
  overflow: auto;
}

.for_approve_connections_container {
  margin-top: 10px;
  height: calc(100vh - 407px);
  overflow: auto;
}

.group_name {
  display: flex;
  gap: 20px;
  font-weight: 600;
}

.approve_button_container {
  display: block;
  text-align: right;
}

.parent_file {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.child_file {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.list_item {
  justify-content: flex-start !important;
  gap: 10px;
}

.child_file_name {
  cursor: pointer;
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file_name {
  cursor: pointer;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
